import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import { HashLink as HLink } from 'react-router-hash-link';

class Contact extends Component {
render() {
   let imgattr = 'image'

   return (

      <section id="contact" className="p-80px-tb">
         <div className="container">
            <div className="row">
               <div className="col-sm-8 offset-sm-2">
                  <div className="section-title text-center m-50px-b">
                     <h2>{homedata.contact.title}</h2>
                  </div>
               </div>
            </div>
            <div className="row row-eq-height ">
               <div className="col-lg-4 col-md-6 contact-address p-30px border-radius-left">
                  <div className="logo-contact text-center p-15px m-15px-b">
                     <HLink className="nav-link" smooth to={`/#home`}>
                        <img className="img-contact" src={process.env.PUBLIC_URL + '/assets/img/logo-contact.png' } alt={imgattr}/>
                     </HLink>
                  </div>
                  <div className="address-box text-center p-15px m-15px-b border-radius">
                     <i className={homedata.contact.contactinfo.phone.icon}></i>
                     <h5>{homedata.contact.contactinfo.phone.title}</h5>
                     <p dangerouslySetInnerHTML={{__html: homedata.contact.contactinfo.phone.info}}></p>
                  </div>
                  <div className="address-box text-center p-15px border-radius">
                     <i className={homedata.contact.contactinfo.email.icon}></i>
                     <h5>{homedata.contact.contactinfo.email.title}</h5>
                     <p dangerouslySetInnerHTML={{__html: homedata.contact.contactinfo.email.info}}></p>
                  </div>
               </div>
               <div className="contact-title col-lg-8 col-md-6 contact-form p-30px border-radius-right">
                  <p className="m-25px-b">{homedata.contact.formtitle}</p>
                  <p className="m-25px-b">{homedata.contact.formdescription}</p>
                  {/* <form id="contact-form" method="post" action="#">
                     <div className="message">
                        <p className="email-success alert alert-success"><i className="icon icon-icon-check-alt2"></i> Your quote has successfully been sent.  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                           <span aria-hidden="true">&times;</span></button>
                        </p>
                        <p className="email-failed alert alert-danger"><i className="icon icon-icon-close-alt2"></i> Something went wrong!  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                           <span aria-hidden="true">&times;</span></button>
                        </p>
                     </div>
                     <div className=" mb13">
                        <input name="name" className="contact-name" id="contact-name" type="text" placeholder="Seu nome" required=""/>
                     </div>
                     <div className="mb13">
                        <input name="email" className="contact-email" id="contact-email" type="email" placeholder="Seu Email" required=""/>
                     </div>
                     <div className="mb13">
                        <input name="office" className="contact-office" id="contact-office" type="text" placeholder="Seu cargo" required=""/>
                     </div>
                     <div className="mb30">
                        <textarea name="message" className="contact-message" id="contact-message" placeholder="Como posso te ajudar?" required=""></textarea>
                     </div>
                     <button className="btn btn-default btn-filled animated-btn">Solicite um orçamento </button>
                  </form> */}
                  {/* <iframe className='iframe-form-contact border-radius' src="https://b24-zakt51.bitrix24.site/crm_form_ar9ze/"></iframe> */}
               </div>
            </div>
         </div>
      </section>

   );
}
}

export default Contact;
