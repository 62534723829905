import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
class Customers extends Component {
render() {
   let imgattr = 'image'
   return (

      <div id='customers'>

         <div className='customes-title'>
            <div className="col-sm-12">
               <div className="section-title">
                  {/* <h2 className="tlt1">{homedata.customers.title}</h2> */}
                  <h2>{homedata.customers.title}</h2>
                  <p>{homedata.customers.caption}</p>
               </div>
            </div>
         </div>
         <div className='customers-logo'>
            <div className="col-lg-12 col-md-12  md-p-50px-b">
               <div className='container-client'>
                     <img src={process.env.PUBLIC_URL + '/assets/img/linx.png' } alt={imgattr}/>
                     <img src={process.env.PUBLIC_URL + '/assets/img/a&m.png' } alt={imgattr}/>
                     <img src={process.env.PUBLIC_URL + '/assets/img/chaski.jpg' } alt={imgattr}/>
                     <img src={process.env.PUBLIC_URL + '/assets/img/childhood.png' } alt={imgattr}/>
                     <img src={process.env.PUBLIC_URL + '/assets/img/movit.png' } alt={imgattr}/>
                     <img src={process.env.PUBLIC_URL + '/assets/img/zenite.png' } alt={imgattr}/>
               </div>
            </div>
         </div>
      </div>
   );
}
}

export default Customers;
