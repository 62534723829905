import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import { HashLink as HLink } from 'react-router-hash-link';

class Heroarea extends Component {
render() {
   return (
      <section className="hero-area circle-wrap">
         {/* <div className="circle x1"></div>
         <div className="circle x2"></div>
         <div className="circle x3"></div>
         <div className="circle x4"></div>
         <div className="circle x5"></div>
         <div className="circle x6"></div>
         <div className="circle x7"></div>
         <div className="circle x8"></div>
         <div className="circle x9"></div>
         <div className="circle x10"></div> */}
         <div className="container">
            <div className="row padding-30-t-b align-items-center">
               <div className="col-md-7 p-100px-t p-50px-b md-p-10px-b ">
                  <h3 className="m-48px-b margin-bottom-20">{homedata.heroarea.title}</h3>
                  <span class="block-text margin-bottom-20">
                     <span data-toggle="typed" data-options='{"strings": ["tecnologia", "comunicação", "engajamento"]}'></span>
                  </span>
                  <p className="m-22px-b">{homedata.heroarea.content}</p>
                  <div className="hero-btn-wrapper">
                     <HLink className="btn btn-default animated-btn" smooth to={homedata.heroarea.btn1url}>{homedata.heroarea.btn1label}</HLink>
                     <HLink className="btn btn-default btn-default-outline animated-btn" smooth to={homedata.heroarea.btn2url}>{homedata.heroarea.btn2label}</HLink>
                  </div>
               </div>
               <div className="col-md-5 p-100px-t p-50px-b md-p-10px-t">
                  <img className="hero-mock" src={process.env.PUBLIC_URL+homedata.heroarea.imgurl1} alt="Hero mockup"/>
               </div>
            </div>
         </div>
      </section>
   );
}
}

export default Heroarea;
