import React from 'react';
import Blog from './components-home/Blog';
import Contact from './components-home/Contact';
import Feature from './components-home/Feature';
import FeatureOne from './components-home/FeatureOne';
import FeatureTwo from './components-home/FeatureTwo';
import Heroarea from './components-home/Heroarea';
import Customers from './components-home/Customers';


const Home = () => {

    return (
      <div>
      <Heroarea />
      <Customers />
      <FeatureOne />
      <Feature />
      {/* <Blog /> */}
      <Contact />
      </div>


    );
}



export default Home;
