import React, { Component } from 'react';
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";

class Footer extends Component {
render() {
   let data = this.props.data;
   let logoAttr = 'logo';
   let publicUrl = process.env.PUBLIC_URL
   let logoLink = publicUrl;
   return (

      <footer id="footer" className="p-70px-t p-30px-b footer">
         <div className="container">
            <div className="row">
               <div className="col-sm-12">
                  <div className="footer-top text-center p-30px-tb">
                     <a className="footer-logo" href={logoLink}><img src={publicUrl + data.footerlogo} alt={logoAttr}/></a>
                     <p>{data.footertext}</p>
                     <div className="social-network">
                        <div className='network-social'>
                           <a href='https://www.linkedin.com/company/pluztech/' target='blank'><i><AiFillLinkedin/></i></a>
                           <a href='https://www.facebook.com/pluztec' target='blank'><i><AiFillFacebook/></i></a>
                           <a href='https://www.instagram.com/pluz.io/' target='blank'><i><AiFillInstagram/></i></a>
                        </div>
                     </div>
                  </div>
                  <div className="footer-copyright p-30px-tb text-center">
                     <p dangerouslySetInnerHTML={{__html: data.copyrighttext}}></p>
                  </div>
               </div>
            </div>
         </div>
      </footer>

   );
}
}

export default Footer;
